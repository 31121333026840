const opts = [
  { id: "polite", label: "Polite" }
]

export class AdjVolitionalForm {
	static get opts() {
    return opts;
  }
	static getAnswer = (word, type, opts) => {
    if (!word || !type || (type !== "i" && type !== "na")) {
      throw new Error("Invalid input");
    }

		let stem = "";
		if (type === "i") {
      stem = /(.*)(?!$)/.exec(word)[0];
      if (word === "いい") {
        stem = "よ";
      }
      return stem + "かろう";
		}
		else if (type === "na") {
      return word + (opts.polite ? "でしょう" : "だろう");
    }
	}
}

export default AdjVolitionalForm;
