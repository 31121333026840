const opts = [
  { id: "neg", label: "Negative" },
]

export class AdjConditionalForm {
  static get opts() {
    return opts;
  }
  static getAnswer = (word, type, opts) => {
    if (!word || !type || (type !== "i" && type !== "na")) {
      throw new Error("Invalid input");
    }
    
    const endings = {
      "i":{"pos":["ければ"], "neg":["くなければ"]},
      "na":{"pos":["ならば", "であれば"], "neg":["ではなかったら", "じゃなかったら", "ではなければ"]}
    };

    const posOrNeg = opts.neg ? "neg" : "pos";
    let stem;

    if (type === "i") {
      stem = /(.*)(?!$)/.exec(word)[0];
      if (word === "いい") {
        stem = "よ";
      }
    }
    else if (type === "na") {
      stem = word;
    }
    return endings[type][posOrNeg].map(end => stem + end);
  }
}

export default AdjConditionalForm;
