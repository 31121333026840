const opts = [
  { id: "neg", label: "Negative" },
  { id: "polite", label: "Polite" },
];

export class AdjNaruForm {
  static get opts() {
    return opts;
  }
  static getAnswer = (word, type, opts) => {
    if (!word || !type || (type !== "i" && type !== "na")) {
      throw new Error("Invalid input");
    }
    
    let ending = "なる";
    if (opts.polite && opts.neg) {
      ending = "なりません";
    } else if (opts.polite) {
      ending = "なります";
    } else if (opts.neg) {
      ending = "ならない";
    }

    let stem = "";
    if (type === "i") {
      stem = /(.*)(?!$)/.exec(word)[0];
      if (word === "いい") {
        stem = "よ";
      }
      return stem + "く" + ending;
    }
    else if (type === "na") {
      return word + "に" + ending;
    }
  }
}

export default AdjNaruForm;