const opts = [
  {id: "polite", label: "Polite"}
]

export class AdjNegativeForm {
  static get opts() {
    return opts;
  }
  static getAnswer = (word, type, opts) => {
    if (!word || !type || (type !== "i" && type !== "na")) {
      throw new Error("Invalid input");
    }

    let ending = "";
    let stem = "";

    if (opts.polite) {
      ending = "ありません";
    } else {
      ending = "ない";
    }

    if (type === "i") {
      stem = /(.*)(?!$)/.exec(word)[0];
      if (word === "いい") {
        stem = "よ";
      }
      return stem + "く" + ending;
    }
    else if (type === "na") {
      if (opts.polite) {
        return [word + "では" + ending, word + "じゃ" + ending, word + "じゃないです"];
      } else {
        return [word + "では" + ending, word + "じゃ" + ending];
      }
    }
  }
}

export default AdjNegativeForm;
