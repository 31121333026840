import React from "react"
import PropTypes from "prop-types"
import Conjugation from './conjugation'
import SEO from "../seo"

const adjectives = require("../japanese/data/adjectives.json");

const AdjConjugation = (props) => {
  const AdjForm = require(`../japanese/adjectives/${props.pageContext.jsname}.js`).default;

  var getAnswer = AdjForm.getAnswer;
  var getForm;
  var randomize;

  if (!Object.prototype.hasOwnProperty.call(AdjForm, 'getAnswer')) {
    var adjform = new AdjForm();
    getAnswer = adjform.getAnswer.bind(adjform);
    getForm = adjform.getForm.bind(adjform)
    randomize = true;
  }

  const title = props.pageContext.title + " Adjective Practice";
  return (
    <>
      <SEO
        title={title}
        description={`${props.pageContext.title} Japanese Adjective Conjugation practice page. Simple, interactive grammar studying page with adjustable settings.`}
      />
      <Conjugation title={title} opts={AdjForm.opts} exceptions={AdjForm.exceptions ? AdjForm.exceptions : []} getAnswer={getAnswer} getForm={getForm} helplink={props.pageContext.helplink} wordData={adjectives} randomize={randomize} types={{"i":"い-Adjective", "na":"な-Adjective"}} />
    </>
  )
}

AdjConjugation.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    jsname: PropTypes.string.isRequired,
    helplink: PropTypes.string
  }),
}

export default AdjConjugation
