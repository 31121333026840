import AdjConditionalForm from "./adj-conditionalform";
import AdjNaruForm from "./adj-naruform";
import AdjNegativeForm from "./adj-negativeform";
import AdjPastForm from "./adj-pastform";
import AdjVolitionalForm from "./adj-volitionalform";

const opts = [
  {id: "adj-conditional", label: "Conditional"},
  {id: "adj-naruform", label: "なる"},
  {id: "adj-negative", label: "Negative"},
  {id: "adj-past", label: "Past"},
  {id: "adj-volitional", label: "Volitional"},
]

const optLabels = {
  "adj-conditional": "Conditional",
  "adj-naruform": "なる",
  "adj-negative": "Negative",
  "adj-past": "Past",
  "adj-volitional": "Volitional",
}

const func = {
  "adj-conditional": AdjConditionalForm,
  "adj-naruform": AdjNaruForm,
  "adj-negative": AdjNegativeForm,
  "adj-past": AdjPastForm,
  "adj-volitional": AdjVolitionalForm,
}

export class Randomize {
  constructor() {
    this.formLabel = "";
  }
	static get opts() {
    return opts;
  }
  getForm() {
    return this.formLabel
  }
	getAnswer(word, type, conjOpts) {
      var activeOpts = Object.keys(conjOpts).filter(function(key) {return conjOpts[key]})
      if (activeOpts.length === 0) {activeOpts = Object.keys(conjOpts)}
      var conj = activeOpts[Math.floor(Math.random()*activeOpts.length)];

      var answerOpts = {}
      let labels = [];
      func[conj].opts.forEach(function(o) {
        answerOpts[o.id] = Math.random() >= 0.5;
        if (answerOpts[o.id]) {labels.push(o.label);}
      });
      var form = ""
      if (labels.length > 0) {form = labels.join(" ")}

      this.formLabel = form + " " + optLabels[conj]
      return {
        "kana": func[conj].getAnswer(word.kana, type, answerOpts),
        "kanji": func[conj].getAnswer(word.kanji, type, answerOpts),
      }
	}
}

export default Randomize;
