const opts = [
  {id: "neg", label: "Negative"},
  {id: "polite", label: "Polite"},
]

export class AdjPastForm {
  static get opts() {
    return opts;
  }
  static getAnswer = (word, type, opts) => {
    if (!word || !type || (type !== "i" && type !== "na")) {
      throw new Error("Invalid input");
    }

    const endings = {
      "i":{
        "plain":{"pos":["かった"], "neg":["くなかった"]},
        "polite":{"pos":["かったです"], "neg":["くなかったです", "くありませんでした"]}
      },
      "na":{
        "plain":{"pos":["だった"], "neg":["ではなかった", "じゃなかった"]},
        "polite":{"pos":["でした"], "neg":["ではありませんでした", "じゃなかったです"]}
      }
    };

    const plainOrPolite = opts.polite ? "polite" : "plain";
    const posOrNeg = opts.neg ? "neg" : "pos";
    let stem = "";

    if(type === "i") {
      stem = /(.*)(?!$)/.exec(word)[0];
      if (word === "いい") {
        stem = "よ";
      }
    }
    else if (type === "na") {
      stem = word;
    }
    return endings[type][plainOrPolite][posOrNeg].map(end => stem + end);
  }
}

export default AdjPastForm;
